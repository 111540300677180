import type {
    ButtonProps,
    ChooseDesignBoardModalProps,
    ObjectShorthandValue,
} from '@archipro-design/aria';
import type { SvgIconSizeValue } from '@archipro-design/icons';
import { Link, useFetcher } from '@remix-run/react';
import { useDebounceFn, useLocalStorageState, useMemoizedFn } from 'ahooks';
import type { DesignBoard, DesignBoardListQuery } from 'generated/graphql';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    DEFAULT_BOARD_TITLE,
    USER_FAVOURITED_ITEM_STORAGE_KEY,
    API_ACTIONS,
    DESIGN_BOARD_REMIX_API,
    USER_BOARD_LIST_STORAGE_KEY,
    USER_ID_STORAGE_KEY,
    DESIGN_BOARD_HOME_LINK,
} from '../config/design-board-config';
import type {
    NewBoardDataProps,
    UserFavouritedItem,
} from '../type/design-board-types';
import {
    findBoardById,
    getFormattedBoardData,
    getSavedBoardDataOfItem,
    getUserFavouritedItems,
    isFavouritedItem,
} from '../util/design-board-utils';
import * as S from '../component/save-to-design-board/SaveToDesignBoard.style';
import { useUser } from '@modules/user';
import { useToaster } from '~/modules/root/context/ToasterContext';
import { useTracker } from '@archipro-website/tracker';
import { useSaveToDesignBoardContext } from '~/modules/design-board/component/context';
import CreateNewBoardAndSavePin from '~/modules/design-board/component/save-to-design-board/CreateNewBoardAndSavePin';
import SaveToBoardChooseLocation from '~/modules/design-board/component/save-to-design-board/SaveToBoardChooseLocation';
import type { ErrorResponse } from '~/modules/root/graphql/responses';
import { assertErrorResponse } from '~/modules/root/graphql/responses';
import { useLogger } from '@archipro-website/logger/client';
import { fetcherIsDone, fetcherIsInit } from '~/utils/fetcherHelper';
import { useFetcherIsActionReload } from '~/hooks/useFetcherIsActionReload';
import type { DesignBoardItem } from './use-design-board-gating';
import { useDesignBoardGating } from './use-design-board-gating';
import type { NewDesignBoardResponse } from '../api/design-board.server';

const MAX_WAIT_TIME = 5000;

interface SaveToDesignBoardProps {
    itemId: UserFavouritedItem['itemId'];
    itemType: UserFavouritedItem['itemType'];
    urlSearchParams?: UserFavouritedItem['urlSearchParams'];
    target?: HTMLElement;
    clickToken?: number;
    offsetLeft?: string;
    offsetTop?: string;
    children?: React.ReactNode;
    onSave: (favouritedItem: UserFavouritedItem) => void;
    onClose?: () => void;
    autoReposition?: boolean;
    heartButton?: ObjectShorthandValue<
        ButtonProps & { heartSize?: SvgIconSizeValue }
    >;
    isFavourited?: boolean;
    closeOnScrollOffset?: number; // close when scroll offset is greater than this value
    toastAutoHideDelay?: number;
    isInitializeOnly?: boolean;
    className?: string;
    disable?: boolean;
    libraryItemIdOverride?: number;
    skipSinglePinSaving?: boolean;
    afterSkipSaving?: (boardId: number, title?: string) => void;
}

export const useSaveToBoardFetchers = ({
    itemId,
    itemType,
    urlSearchParams,
    onSave,
    toastAutoHideDelay = 6,
    isInitializeOnly = false,
    disable,
    libraryItemIdOverride,
    skipSinglePinSaving = false,
    afterSkipSaving,
}: SaveToDesignBoardProps) => {
    const logger = useLogger();
    const user = useUser();
    const guestID = user.__typename === 'Me' ? user.TrackedGuest?.ID : 0;
    const isLoggedIn = user.__typename === 'Me';
    const [localFavouritedItems, setLocalFavouritedItems] =
        useLocalStorageState<UserFavouritedItem[]>(
            USER_FAVOURITED_ITEM_STORAGE_KEY,
            { defaultValue: [] }
        );
    const [localBoardList, setLocalBoardList] = useLocalStorageState<
        ChooseDesignBoardModalProps['designBoards']
    >(USER_BOARD_LIST_STORAGE_KEY, {
        defaultValue: [],
    });

    const [localUserId, setLocalUserId] = useLocalStorageState<number>(
        USER_ID_STORAGE_KEY,
        { defaultValue: 0 }
    );

    const [designBoardsData, setDesignBoardsData] = useState<
        ChooseDesignBoardModalProps['designBoards']
    >(
        user?.ID !== localUserId
            ? []
            : getSavedBoardDataOfItem(
                  localBoardList,
                  itemId,
                  itemType,
                  localFavouritedItems
              )
    );

    const saveToBoardContext = useSaveToDesignBoardContext();

    const queryFetcher = useFetcher<DesignBoardListQuery>();
    const saveFetcher = useFetcher<UserFavouritedItem | ErrorResponse>();
    const createFetcher = useFetcher<
        (NewDesignBoardResponse & UserFavouritedItem) | ErrorResponse
    >();

    const [isSaveFavoriteLoading, setIsSaveFavoriteLoading] = useState(false);
    const [isCreateBoardLoading, setIsCreateBoardLoading] = useState(false);

    const { toast, closeToast } = useToaster({
        styleRule: S.Toaster,
        autoHideDelay: toastAutoHideDelay,
    });

    const onSaveMemo = useMemoizedFn(onSave);

    const clickHereLink = useMemo(
        () => ({
            as: Link,
            to: DESIGN_BOARD_HOME_LINK,
            children: 'View here',
            onClick: closeToast,
        }),
        [closeToast]
    );
    const tracker = useTracker();
    const onClickedBoardTitleRef = useRef('');
    const onClickedBoardUrlSegmentRef = useRef('');

    // todo: use new api to get user design boards & pins
    const fetchUserDesignBoards = useCallback(
        (forceRefetch = false) => {
            if (
                forceRefetch ||
                ((fetcherIsInit(queryFetcher) || fetcherIsDone(queryFetcher)) &&
                    (fetcherIsInit(saveFetcher) || fetcherIsDone(saveFetcher)))
            ) {
                queryFetcher.submit(
                    {
                        offset: '0',
                    },
                    {
                        method: 'get',
                        action: DESIGN_BOARD_REMIX_API,
                    }
                );
            }
        },
        [queryFetcher, saveFetcher]
    );

    const closeSaveToBoardDrawer = useCallback(() => {
        saveToBoardContext.dispatch({ type: 'CloseDrawer' });
        fetchUserDesignBoards();
    }, [fetchUserDesignBoards, saveToBoardContext]);

    const onSaveFavouriteItem = useCallback(
        (
            _e?: React.SyntheticEvent<HTMLElement>,
            boardId = 0,
            boardTitle = '',
            urlSegment = ''
        ) => {
            tracker.log('SaveSubmit', {
                url: new URL(window.location.href),
                data: {
                    LibraryItemID: libraryItemIdOverride ?? itemId,
                },
                ga4Data: {
                    LibraryItemID: libraryItemIdOverride ?? itemId,
                    guest_id: guestID,
                },
            });

            if (!skipSinglePinSaving) {
                if (!itemId || !itemType) {
                    toast('Error, the itemId or itemType is missing', {
                        type: 'error',
                        clickHereLink,
                    });
                    setIsSaveFavoriteLoading(false);
                    return;
                }

                setIsSaveFavoriteLoading(true);

                saveFetcher.submit(
                    {
                        boardId: boardId.toString(),
                        itemId: itemId.toString(),
                        itemType: itemType,
                        _action: API_ACTIONS.SAVE_ITEM_TO_DESIGN_BOARD,
                        urlSearchParams: urlSearchParams || '',
                    },
                    {
                        method: 'post',
                        action: DESIGN_BOARD_REMIX_API,
                    }
                );
            } else if (afterSkipSaving) {
                afterSkipSaving(
                    boardId,
                    boardId > 0 ? boardTitle : DEFAULT_BOARD_TITLE
                );

                closeSaveToBoardDrawer();
            }

            onClickedBoardTitleRef.current = boardTitle;
            onClickedBoardUrlSegmentRef.current = urlSegment;
        },
        [
            tracker,
            libraryItemIdOverride,
            itemId,
            itemType,
            saveFetcher,
            urlSearchParams,
            toast,
            clickHereLink,
            guestID,
            closeSaveToBoardDrawer,
            skipSinglePinSaving,
            afterSkipSaving,
        ]
    );

    const onDeleteFavouriteItem = useCallback(
        (id: number, boardId?: number) => {
            const savedBoardId = boardId || 0;
            setIsSaveFavoriteLoading(true);
            saveFetcher.submit(
                {
                    ID: id.toString(),
                    boardId: savedBoardId.toString(),
                    itemId: itemId.toString(),
                    itemType: itemType,
                    _action: API_ACTIONS.DELETE_ITEM_FROM_DESIGN_BOARD,
                },
                {
                    method: 'post',
                    action: DESIGN_BOARD_REMIX_API,
                }
            );
        },
        [itemId, itemType, saveFetcher]
    );

    const onCreateNewBoardAndSave = async (newBoardData: NewBoardDataProps) => {
        setIsCreateBoardLoading(true);

        createFetcher.submit(
            {
                title: newBoardData.title,
                isPrivate: newBoardData.isPrivate ? '1' : '0',
                description: newBoardData.description || '',
                itemId: itemId.toString(),
                itemType: itemType,
                urlSearchParams: urlSearchParams || '',
                _action: API_ACTIONS.CREATE_DESIGN_BOARD_AND_SAVE_ITEM,
            },
            {
                method: 'post',
                action: DESIGN_BOARD_REMIX_API,
            }
        );
        debounceCloseDrawer();
    };

    const resetFormattedBoardsData = useCallback(() => {
        if (queryFetcher.data?.DesignBoardListItems?.nodes) {
            const originData = queryFetcher.data?.DesignBoardListItems
                ?.nodes as DesignBoard[];
            const formattedData = getFormattedBoardData(
                originData,
                itemId,
                itemType
            );
            if (formattedData && designBoardsData !== formattedData) {
                setDesignBoardsData(formattedData);
                setLocalBoardList(
                    formattedData.map((item) => {
                        if (typeof item === 'object' && item !== null) {
                            return { ...item, isSaved: false };
                        }
                        return item;
                    })
                );

                const newFavouritedItems = getUserFavouritedItems(originData);
                if (newFavouritedItems.length > localFavouritedItems.length) {
                    setLocalFavouritedItems(newFavouritedItems);
                    window.dispatchEvent(new Event('storage'));
                }
            }
        }
    }, [
        itemId,
        itemType,
        designBoardsData,
        localFavouritedItems,
        setLocalFavouritedItems,
        setLocalBoardList,
        queryFetcher.data?.DesignBoardListItems?.nodes,
    ]);

    const saveItemToLocalStorage = useCallback(
        (item: UserFavouritedItem, itemDeleted = false) => {
            const newLocalItems = itemDeleted
                ? localFavouritedItems.filter(
                      (it) => !item.ID || it.ID !== item.ID
                  )
                : [item, ...localFavouritedItems];
            setLocalFavouritedItems(newLocalItems);
            window.dispatchEvent(new Event('storage'));
        },
        [localFavouritedItems, setLocalFavouritedItems]
    );

    const hasNewBoardInLocalStorage = useCallback(() => {
        return localFavouritedItems.some(
            (item) =>
                findBoardById(item.boardId, designBoardsData) === undefined
        );
    }, [localFavouritedItems, designBoardsData]);

    const handleSaveFetcherData = useCallback(() => {
        const data = saveFetcher.data;
        if (!data || assertErrorResponse(data)) {
            return;
        }
        onSaveMemo(data);
        saveItemToLocalStorage(data);
        const boardTitle = !isLoggedIn
            ? DEFAULT_BOARD_TITLE
            : onClickedBoardTitleRef.current ||
              findBoardById(data.boardId, designBoardsData)?.title ||
              DEFAULT_BOARD_TITLE;
        if (isLoggedIn) {
            toast(`Saved to "${boardTitle}" `, {
                type: 'notification',
                clickHereLink: {
                    ...clickHereLink,
                    to: `${DESIGN_BOARD_HOME_LINK}/${onClickedBoardUrlSegmentRef.current}`,
                },
            });
        }

        const clickedBoard = saveToBoardContext.state.myBoardList.find(
            (board) => board.id === data?.boardId
        );
        clickedBoard &&
            saveToBoardContext.dispatch({
                type: 'SetBoardPinData',
                payload: {
                    myBoardList: [
                        clickedBoard,
                        ...saveToBoardContext.state.myBoardList.filter(
                            (board) => board.id !== clickedBoard.id
                        ),
                    ],
                    isDrawerOpen: false,
                },
            });
    }, [
        designBoardsData,
        isLoggedIn,
        onSaveMemo,
        saveFetcher.data,
        saveItemToLocalStorage,
        clickHereLink,
        toast,
        saveToBoardContext,
    ]);

    const handleDeleteSavedItem = useCallback(() => {
        if (!saveFetcher.data || assertErrorResponse(saveFetcher.data)) {
            return;
        }
        saveItemToLocalStorage(saveFetcher.data, true);
        toast(`Pin removed`, {
            type: 'success',
        });
    }, [saveFetcher.data, saveItemToLocalStorage, toast]);

    const nonLoginUserSaveToBoard = useCallback(() => {
        const isFavourited = isFavouritedItem(
            itemId,
            itemType,
            localFavouritedItems,
            undefined
        );

        if (isFavourited) {
            const clickedItem = localFavouritedItems.find(
                (item) => item.itemId === itemId && item.itemType === itemType
            );
            if (clickedItem?.ID) {
                onDeleteFavouriteItem(clickedItem.ID, clickedItem.boardId);
            }
        } else {
            onSaveFavouriteItem();

            if (!skipSinglePinSaving) {
                onSaveMemo({ boardId: 0, itemId, itemType, ID: 0 });

                // show toast 300ms after clicking/tapping the heart
                setTimeout(() => {
                    toast(`Saved to "${DEFAULT_BOARD_TITLE}" `, {
                        type: 'notification',
                        clickHereLink,
                    });
                }, 300);
            }
        }
    }, [
        itemId,
        itemType,
        localFavouritedItems,
        onDeleteFavouriteItem,
        onSaveFavouriteItem,
        onSaveMemo,
        toast,
        clickHereLink,
        skipSinglePinSaving,
    ]);

    const { run: debounceCloseDrawer } = useDebounceFn(
        () => {
            saveToBoardContext.state.isDrawerOpen && closeSaveToBoardDrawer();
        },
        {
            wait: MAX_WAIT_TIME,
        }
    );

    const openCreateNewBoardDrawer = (item: DesignBoardItem) => {
        saveToBoardContext.dispatch({
            type: 'OpenDrawer',
            payload: {
                drawerChild: (
                    <CreateNewBoardAndSavePin
                        selectedPinId={item.itemId}
                        selectedPinType={item.itemType}
                        urlSearchParams={item.urlSearchParams}
                        onClose={closeSaveToBoardDrawer}
                        libraryItemIdOverride={item.libraryItemIdOverride}
                        skipSinglePinSaving={skipSinglePinSaving}
                        afterSkipSaving={afterSkipSaving}
                    />
                ),
            },
        });
    };

    const openSaveToBoardDrawer = (item: DesignBoardItem) => {
        saveToBoardContext.dispatch({
            type: 'OpenDrawer',
            payload: {
                drawerChild: (
                    <SaveToBoardChooseLocation
                        selectedPinId={item.itemId}
                        selectedPinType={item.itemType}
                        onClose={closeSaveToBoardDrawer}
                        urlSearchParams={item.urlSearchParams}
                        onClickCreateNewBoard={() =>
                            openCreateNewBoardDrawer(item)
                        }
                        libraryItemIdOverride={item.libraryItemIdOverride}
                        skipSinglePinSaving={skipSinglePinSaving}
                        afterSkipSaving={afterSkipSaving}
                    />
                ),
                drawerAnchor: 'right',
            },
        });
    };

    const { designBoardGatingEnabled, showAuthModal } = useDesignBoardGating();

    const onClickHeartIcon = () => {
        if (disable) return;
        tracker.log('PinMenuAdd', {
            url: new URL(window.location.href),
            data: {
                LibraryItemID: libraryItemIdOverride ?? itemId,
            },
        });
        if (designBoardGatingEnabled) {
            showAuthModal({
                itemId,
                itemType,
                urlSearchParams,
                libraryItemIdOverride,
            });
            return;
        }
        isLoggedIn
            ? openSaveToBoardDrawer({
                  itemId,
                  itemType,
                  urlSearchParams,
                  libraryItemIdOverride,
              })
            : nonLoginUserSaveToBoard();
    };

    // handle queryFetcher.data changes
    useEffect(() => {
        if (
            queryFetcher.data?.DesignBoardListItems?.nodes.length !==
            designBoardsData.length
        ) {
            resetFormattedBoardsData();
        }
    }, [queryFetcher.data, designBoardsData.length, resetFormattedBoardsData]);

    const saveIsActionReload = useFetcherIsActionReload(saveFetcher);
    // handle save to board mutation response
    useEffect(() => {
        if (!isSaveFavoriteLoading) {
            return;
        }

        if (fetcherIsDone(saveFetcher) || saveIsActionReload) {
            if (saveFetcher.data && 'itemDeleted' in saveFetcher.data) {
                handleDeleteSavedItem();
            } else if (saveFetcher.data && 'boardId' in saveFetcher.data) {
                handleSaveFetcherData();
            } else if (saveFetcher.data && 'error' in saveFetcher.data) {
                toast(`${saveFetcher.data.message} `, {
                    type: 'error',
                    clickHereLink,
                });
                if (saveFetcher.data.message.includes('Already saved to')) {
                    fetchUserDesignBoards();
                }
            } else {
                toast(
                    'Something went wrong. Please refresh the page and try again.',
                    {
                        type: 'error',
                    }
                );
                logger.error(
                    'Unexpected error of saveFetcher.data:',
                    saveFetcher.data
                );
            }
            setIsSaveFavoriteLoading(false);
        }

        if (saveIsActionReload) {
            // if stay in this status for X seconds, close the popup first
            const timeoutAction = setTimeout(() => {
                handleSaveFetcherData();
                fetchUserDesignBoards(true);
                setIsSaveFavoriteLoading(false);
            }, 3000);

            return () => {
                clearTimeout(timeoutAction);
            };
        }
    }, [
        saveFetcher.data,
        saveFetcher,
        fetchUserDesignBoards,
        handleSaveFetcherData,
        handleDeleteSavedItem,
        toast,
        clickHereLink,
        isSaveFavoriteLoading,
        logger,
        saveIsActionReload,
    ]);

    // handle create new board mutation response
    useEffect(() => {
        if (
            isCreateBoardLoading &&
            fetcherIsDone(createFetcher) &&
            createFetcher.data
        ) {
            if ('boardId' in createFetcher.data) {
                const { boardId, boardTitle, itemId, itemType } =
                    createFetcher.data;

                if (!skipSinglePinSaving) {
                    const item = { boardId, itemId, itemType, ID: 0 };
                    onSaveMemo(item);
                    fetchUserDesignBoards();
                    saveItemToLocalStorage(item);

                    toast(`Saved to "${boardTitle}" `, {
                        type: 'notification',
                        clickHereLink: {
                            ...clickHereLink,
                            to: `${DESIGN_BOARD_HOME_LINK}/${createFetcher.data.urlSegment}`,
                        },
                    });

                    const newBoard = {
                        id: boardId,
                        boardName: boardTitle || DEFAULT_BOARD_TITLE,
                        urlSegment: createFetcher.data.urlSegment,
                    };

                    saveToBoardContext.dispatch({
                        type: 'SetBoardPinData',
                        payload: {
                            myBoardList: [
                                newBoard,
                                ...saveToBoardContext.state.myBoardList,
                            ],
                            isDrawerOpen: false,
                        },
                    });
                } else if (afterSkipSaving) {
                    afterSkipSaving(boardId, boardTitle);
                    fetchUserDesignBoards();
                }
            } else if (createFetcher.data && 'error' in createFetcher.data) {
                toast(createFetcher.data.message, {
                    type: 'error',
                    clickHereLink,
                });
            }
            setIsCreateBoardLoading(false);
            closeSaveToBoardDrawer();
        }
    }, [
        onSaveMemo,
        createFetcher.data,
        createFetcher,
        isCreateBoardLoading,
        fetchUserDesignBoards,
        saveItemToLocalStorage,
        closeSaveToBoardDrawer,
        toast,
        clickHereLink,
        saveToBoardContext,
        afterSkipSaving,
        skipSinglePinSaving,
    ]);

    // if user.ID changed, reset the local data
    useEffect(() => {
        if (user?.ID !== localUserId) {
            setLocalUserId(user?.ID || 0);
            setLocalFavouritedItems([]);
            setLocalBoardList([]);
            window.dispatchEvent(new Event('storage'));

            saveToBoardContext.dispatch({
                type: 'SetBoardPinData',
                payload: {
                    myBoardList: [],
                },
            });
        }
    }, [
        user?.ID,
        localUserId,
        setLocalUserId,
        setLocalFavouritedItems,
        setLocalBoardList,
        saveToBoardContext,
    ]);

    useEffect(() => {
        if (isInitializeOnly && fetcherIsInit(queryFetcher)) {
            setLocalFavouritedItems([]);
            isLoggedIn && fetchUserDesignBoards();
        }
    }, [
        isLoggedIn,
        fetchUserDesignBoards,
        isInitializeOnly,
        queryFetcher,
        setLocalFavouritedItems,
    ]);

    return {
        createFetcher,
        localFavouritedItems,
        isCreateBoardLoading,
        onClickHeartIcon,
        hasNewBoardInLocalStorage,
        fetchUserDesignBoards,
        onSaveFavouriteItem,
        onDeleteFavouriteItem,
        onCreateNewBoardAndSave,
        nonLoginUserSaveToBoard,
    };
};
